export const POST_ANSWER = "POST_ANSWER";
export const RECEIVE_ANSWERS = " RECEIVE_ANSWERS";
export const SET_TOKEN = "SET_TOKEN";
export const FALSE_ANSWER = "FALSE_ANSWER";
export const SET_NAME = "SET_NAME";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_HISTORY = "SET_HISTORY";
export const SET_TARGET = "SET_TARGET";
export const ASK_FAQ = "ASK_FAQ";
export const INITIALIZED = "INITIALIZED"
export const SET_MEDIUM = "SET_MEDIUM"
export const SET_ERROR = "SET_ERROR";
export const SET_PREVIEW_EXPIRATION_DATE = "SET_PREVIEW_EXPIRATION_DATE";
export const SET_CLIENT_KEY = "SET_CLIENT_KEY";