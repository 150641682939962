import React, { Component } from "react";

class Splash extends Component {
  render() {

    let { heightValue = "100vh" } = this.props;

    return (

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: heightValue, backgroundColor: "#f5f7fc" }}>
        <img style={{ height: 40, width: 40 }} className="splashAnimation" src="/images2/other/novovilleLogoBig.png" />
      </div>
    )
  }
}

export default Splash;
