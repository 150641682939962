import React, { Component } from 'react';
import styled from 'styled-components';
import { TYPE_INCOMING } from '../constants/messageTypes';
import { blueColor, greenColor } from "../constants/colors"

class MessageTitle extends Component {

    render() {
        return (
            <StyledMessageTitle className={this.props.className} type={this.props.type}>
                <MessageTime className="messageTime" >
                    {this.props.time}
                </MessageTime>
                <MessageName className='messageName'>
                    {this.props.name}
                </MessageName>
                <MessageBullet type={this.props.type} />
            </StyledMessageTitle>
        )
    }
}

export default MessageTitle


const MessageTime = styled.div`
color: #a8aab1;
margin-left: 10px;
`

const MessageName = styled.div`
color: #a8aab1;
margin-left: 10px;
`

const MessageBullet = styled.div`
border-radius: 1000px;
height: 10px;
width: 10px;
margin: 10px;
background-color:${props => props.type === TYPE_INCOMING ? blueColor : greenColor};
`

const StyledMessageTitle = styled.div`
display:flex;
flex-direction:${props => props.type === TYPE_INCOMING ? "row" : "row-reverse"};
align-items:center
margin-left:${props => props.type === TYPE_INCOMING ? "auto" : "unset"}
margin-right:${props => props.type === TYPE_INCOMING ? "unset" : "auto"}
flex-shrink: 0;
`