import React, { Component } from 'react';
import './App.css';
import "./novovilleSharedCode/src/services/facebookGoogleScripts";
import WebChatContainer from './Components/WebChatContainer';
import envData from "./novovilleSharedCode/src/env.json";


if (!envData.WITH_LOGS) {
  console.log = () => { }
  console.info = () => { }
  console.warn = () => { }
}


class App extends Component {
  render() {
    return (
      <div className="App">
        <WebChatContainer className='container1' />
      </div>
    );
  }
}

export default App;