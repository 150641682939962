import React, { Component } from 'react';
import Message from './Message';
import * as MSGTYPES from '../constants/messageTypes';
import MessageTitle from './MessageTitle';
import { getDateTime } from '../constants/functions'
import styled from 'styled-components';
import { greenColor } from '../constants/colors';
export default class PostedAnswer extends Component {

    render() {
        const messageTime = getDateTime();
        const messageName = "ME";
        return (
            <MessageContainer isEmpty={this.props.text} >
                <MessageTitle time={messageTime} name={messageName} type={this.props.type} />
                <MessageBody className="messageBody">{this.props.text}</MessageBody>
            </MessageContainer>
        )
    }
}

const MessageBody = styled.div`
padding: 13px;
                                margin-bottom: 10px;
                                border-radius: 7px;
margin-left: 0
background-color:${greenColor};
text-align: left;
`

const MessageContainer = styled.div`
display: ${props => props.isEmpty ? "flex" : "none"} ;
flex-direction: column;
color:white
flex-shrink:0;
align-items:flex-start;
`