import React, { Component } from 'react'
import styled from 'styled-components';
import Message from './Message';
import { connect } from 'react-redux';
import { TYPE_FREETEXT } from '../constants/messageTypes'
import LoadingPage from './LoadingPage'
import TypingEffect from './TypingEffect';
import Splash from '../novovilleSharedCode/src/features/startup/Splash';

require('intersection-observer');

const ua = navigator.userAgent.toLowerCase();
const isAndroid = ua.indexOf("android") > -1;
const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
console.log(ua);



class MessageList extends Component {
    constructor(props) {
        super(props);
        if (!isAndroid && !isIos) {
            let textInput = document.getElementsByName('textInput')[0]
            if (textInput) {
                // textInput.focus();
            }
        }

    }
    scrollToBottom = () => {
        if (!this.messagesEnd) return;
        if (!isAndroid && !isIos) {

            document.getElementsByName('textInput')[0].focus();
            //this.intersectionObserver.observe(this.messagesEnd);
        }

        this.messagesEnd.scrollIntoView({ behavior: "smooth" });


    }
    imageLoaded = () => {
        this.scrollToBottom();
    }

    componentDidMount() {
        if (this.props.messages.length > 0) {
            this.scrollToBottom();
        }
    }

    componentDidUpdate() {
        if (this.props.messages.length > 0) {

            this.scrollToBottom();
            if (isAndroid || isIos) {
                let textInput = document.getElementsByName('textInput')[0]
                if (textInput) {
                    // textInput.blur();
                }
            }
        }
    }

    render() {
        if (!this.props.initialized)
            return (
                <LoadingPage />
            )
        if (this.props.history) {
            var historyContent = this.props.history.map((item, index) => {
                if (item.type === TYPE_FREETEXT) {
                    return (
                        <StyledMessage className="messageOuterContainer" answered={item.answered} name={this.props.name.text} key={index} text={item.text} answers={item.answers} />
                    )
                }
                else {
                    return (
                        <StyledMessage className="messageOuterContainer" answered={item.answers[item.answered]} name={this.props.name.text} key={index} text={item.text} answers={item.answers} />
                    )
                }
            })
        }
        let typing = this.props.loading ? <TypingEffect /> : null;
        let content = this.props.messages.map(((item, index) => {
            return (
                <StyledMessage imageLoaded={this.imageLoaded} className="messageOuterContainer" answered={item.answered} name={this.props.name.text} key={index} text={item.text} answers={item.answers} />
            )
        }
        ));
        return (

            <StyledMessageListContainer className="MessageList" onTouchStart={(() => document.getElementsByClassName('input')[0].blur())}>
                {historyContent}
                {content}
                {typing}
                <div class="lastItem" style={{ float: "left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </StyledMessageListContainer >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        history: state.reducer.history,
        messages: state.reducer.messages,
        name: state.reducer.name,
        initialized: state.reducer.initialized,
        loading: state.reducer.loading
    }
}

export default connect(mapStateToProps)(MessageList)

const StyledMessageListContainer = styled.div`
display:flex;
overflow-y: auto;
overflow-x:hidden; 
padding: 0px 10px !important;
-webkit-overflow-scrolling: touch;
background-color: ;
flex: 1;
flex-direction: column;
color: white;
`

const StyledMessage = styled(Message)`
display:flex;
flex-direction:column;
-webkit-transform: translateZ(0px);
flex-shrink: 0;
&:first-child {
margin-top: auto
}
word-wrap: break-word;
`