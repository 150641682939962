import React from 'react';
import styled from 'styled-components';

export default function ErrorMessage(props) {

    return (
        <StyledContainer className="errorContainer ">
            <h3>{props.text}</h3>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
display:flex;
background-color:whitesmoke;
flex-grow:1;
height:100%;
align-items: center;
justify-content: center;
flex-direction:column;
font-size: 1rem;
color:#444444;
`;