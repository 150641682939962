import React, { Component } from 'react'
import styled from 'styled-components'
import TextInputContainer from './TextInputContainer';
import { bindActionCreators } from 'redux'
import MessageList from './MessageList';
import { connect } from 'react-redux';
import { initChatFinal } from '../actions';
import { translate } from '../novovilleSharedCode/src/services/TranslationService'
import ErrorMessage from './ErrorMessage';

class WebChatContainter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: false
        }
    }

    componentDidMount() {
        const { initChatFinal } = this.props;
        if (window.parameters["name"]) {
            initChatFinal(window.parameters["name"],
                window.parameters["Token"],
                window.parameters["language"],
                window.parameters["target"],
                window.parameters["client_key"],
                window.parameters["preview_expiration_date"])

        } else {
            this.showError();
        }

    }

    showError = () => {
        let error = translate("OTHER.errorOcurred");
        this.setState({ error })

    }

    render() {
        debugger;
        if (!this.state.error && (!this.props.error)) {
            return (
                <OuterWrapper className="outer">
                    <Wrapper className="container2">
                        <MessageList className="messageList" />
                        <TextInputContainer></TextInputContainer>
                    </Wrapper>
                </OuterWrapper>
            )
        }
        else {
            return (
                <OuterWrapper className="outer">
                    <Wrapper className="container2">
                        <ErrorMessage text={this.state.error ? this.state.error : this.props.error.details.error_result[0]} />
                        <TextInputContainer></TextInputContainer>
                    </Wrapper>
                </OuterWrapper>
            )
        }
    }
};

const mapStateToProps = (state) => {
    debugger;
    return {
        error: state.reducer.error

    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        initChatFinal
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WebChatContainter);

const Wrapper = styled.div`
display:flex;
background-color:whitesmoke;
max-width:800px;
width:100%;
height:100%;
width:100%
flex-direction:column;
`;

const OuterWrapper = styled.div` 
width: 100%;
height: 100%;
display: flex;
color: white;
justify-content: center;
`;