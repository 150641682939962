import *  as ATypes from '../constants/ActionTypes';
import Fingerprint2 from 'fingerprintjs2';
import WEBAPI from '../novovilleSharedCode/src/services/WEBAPI';
import * as MSGTypes from '../constants/messageTypes';
import { getTranslations } from '../novovilleSharedCode/src/shared/actions';
import { getLanguage } from '../novovilleSharedCode/src/services/GNRL';
import * as AuthTypes from "../novovilleSharedCode/src/shared/actionTypes";
import { wrapWithDelay } from "../novovilleSharedCode/src/services/GNRL"
import { webChatOpend } from "../novovilleSharedCode/src/services/analyticsActions";

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export const getfingerprint = () => {
    // MONKEYPATCHED TO ACCOUNT FOR KIRKLEES DISASTER

    let id = localStorage.getItem("visitor_id");
    if (id) {
        return id;
    } else {
        let uniqueId = getRandomInt(100000000000, 999999999999) + "_" + Date.now();
        localStorage.setItem("visitor_id", uniqueId);
        return uniqueId;
    }

    // var options = {
    //     excludes: { webgl: true, canvas: true, plugins: true, enumerateDevices: true }
    // }

    // return Fingerprint2.getPromise(options)
    //     .then((components) => {
    //         var values = components.map(function (component) { return component.value })
    //         var fingerprint = Fingerprint2.x64hash128(values.join(''), 31)
    //         return fingerprint;
    //     })
}

export const initChatFinal = (name, token, language, target, clientKey, previewExpirationDate) => {
    let lang = language || getLanguage()
    let targ = target || "consultations";
    return (dispatch, getState) => {
        if (token) {
            dispatch(getTranslations)
                .then(() => { dispatch(setName(name)); webChatOpend(name) })
                .then(() => dispatch(setTarget(targ)))
                .then(() => dispatch(setToken(token)))
                .then(() => dispatch(setMedium('apps')))//  ASSUMPTION If the user provides TOKEN then consider that it has to set medium as apps
                .then(() => dispatch(setLanguage(lang)))
                .then(() => dispatch(postInit(getState().reducer.token)))

        }
        else {
            setTimeout(() => {
                dispatch(getTranslations)
                    .then(() => { dispatch(setName(name)); webChatOpend(name) })
                    .then(() => dispatch(setTarget(targ)))
                    .then(() => dispatch(setMedium('open')))
                    .then(() => dispatch(setClientKey(clientKey)))
                    .then(() => dispatch(setPrevieExpirationDate(previewExpirationDate)))
                    .then(() => getfingerprint())
                    .then((test) => { dispatch(setFingeiptintToken(test)) })
                    .then(() => dispatch(setLanguage(lang)))
                    .then(() => dispatch(postInit(getState().reducer.token)))
                    .catch((er) => { debugger; if (er.details.error_code === "203" || er.details.error_code === "164") { dispatch(setError(er)) } else { throw er } })
            }
                , 250)
        }
    }
}


export const postInit = (token) => {
    return (dispatch, getState) => {
        return WEBAPI.postAnswer(getState().reducer.name,
            '_JUST_OPENED_THREAD_',
            token, getState().reducer.language,
            getState().reducer.target,
            getState().reducer.medium,
            getState().reducer.clientKey,
            getState().reducer.previewExpirationDate)
            .then(res => {
                debugger;
                if (res.result.type == MSGTypes.TYPE_QUESTION) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    })
                }
                else if (res.result.type == MSGTypes.TYPE_FREETEXT) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    })

                }
                else if (res.result.type === MSGTypes.TYPE_STATEMENT) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    });
                }
                else {
                    if (!res.result.type) {
                        dispatch({
                            type: ATypes.ASK_FAQ,
                            payload: res.result
                        })
                    }
                    if (res.result.name == MSGTypes.SYSTEM_TYPE_ALLREADY_ANSWERED || res.result.name == MSGTypes.SYSTEM_CONSULTATION_CLOSED) {
                        dispatch({
                            type: ATypes.RECEIVE_ANSWERS,
                            payload: res.result
                        })
                    }
                }
                return (res)
            })
            .then(res => {
                dispatch({
                    type: ATypes.SET_HISTORY,
                    payload: res.result.history
                })
            })


    }
}
const setError = (error) => {
    return dispatch => {
        return dispatch({
            type: ATypes.SET_ERROR,
            payload: error
        })
    }
}
const setClientKey = (clientKey) => {
    return dispatch => {
        return dispatch({
            type: ATypes.SET_CLIENT_KEY,
            payload: clientKey
        })
    }
}
const setPrevieExpirationDate = (previeExpirationDate) => {
    return dispatch => {
        return dispatch({
            type: ATypes.SET_PREVIEW_EXPIRATION_DATE,
            payload: previeExpirationDate
        })
    }
}
export const postAnswer = (answer) => {
    return (dispatch, getState) => {
        dispatch({
            type: ATypes.POST_ANSWER,
            payload: { text: answer }
        });
        return wrapWithDelay(WEBAPI.postAnswer(getState().reducer.name,
            answer,
            getState().reducer.token,
            getState().reducer.language,
            getState().reducer.target,
            getState().reducer.medium,
            getState().reducer.clientKey,
            getState().reducer.previewExpirationDate
        ), 1500)
            .then((res) => {
                if (res.result.type === MSGTypes.TYPE_QUESTION) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    });
                }
                else if (res.result.type === MSGTypes.TYPE_FREETEXT) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    });
                }
                else if (res.result.type === MSGTypes.TYPE_STATEMENT) {
                    dispatch({
                        type: ATypes.RECEIVE_ANSWERS,
                        payload: res.result
                    });
                }
                else {
                    if (!res.result.type) {
                        dispatch({
                            type: ATypes.ASK_FAQ,
                            payload: res.result
                        })
                    }
                    else {
                        if (res.result.name) {

                            if (res.result.name == MSGTypes.SYSTEM_TYPE_DONT_UNDERSTAND) {
                                dispatch({
                                    type: ATypes.FALSE_ANSWER,
                                    payload: res.result
                                })
                            }
                            else if (res.result.name == MSGTypes.SYSTEM_TYPE_ALLREADY_ANSWERED || res.result.name == MSGTypes.SYSTEM_CONSULTATION_CLOSED) {
                                dispatch({
                                    type: ATypes.RECEIVE_ANSWERS,
                                    payload: res.result
                                })
                            }
                        }
                    }
                }
            })
    }
}

export const setToken = (token) => {
    return dispatch => {
        return dispatch({
            type: AuthTypes.REFRESH_TOKEN_RES,
            data: token
        })
    }
}

export const setMedium = (medium) => {

    return dispatch => {
        return new Promise((res, rej) => {
            res(dispatch({
                type: ATypes.SET_MEDIUM,
                payload: medium
            }))
        })
    }
}
export const setName = (name) => {
    return dispatch => {
        return new Promise((res, rej) => {
            res(dispatch({
                type: ATypes.SET_NAME,
                payload: name
            }))
        })
    }
}

export const setLanguage = (language) => {
    return dispatch => {
        return new Promise((res, rej) => {
            res(dispatch({
                type: ATypes.SET_LANGUAGE,
                payload: language
            }))
        })
    }
}

export const setTarget = (target) => {
    return dispatch => {
        return new Promise((res, rej) => {
            res(dispatch({
                type: ATypes.SET_TARGET,
                payload: { target }
            }))
        })
    }
}

export const setFingeiptintToken = (token) => {
    return dispatch => {
        return new Promise((res, rej) => {
            res(dispatch({
                type: ATypes.SET_TOKEN,
                payload: token
            }))
        })
    }
}