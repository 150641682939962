import * as ATypes from '../constants/ActionTypes';
import * as R from 'ramda';

const reducer = (state = { initialized: false, loading: true, messages: [], token: '', activeQuestion: {}, name: 'watford_vote', history: [], medium: 'open' }, action) => {
   switch (action.type) {
      case ATypes.POST_ANSWER:
         if (action.payload.text === "RESTART_CHAT") {
            return {
               ...state,
               messages: [],
               activeQuestion: {},
               history: []
            }
         }

         let newState = R.clone(state);
         let lastMessageIndex = state.messages.length - 1;
         let lastMessage = { ...state.messages[lastMessageIndex] }
         lastMessage.answered = action.payload.text
         newState.messages = [...state.messages.slice(0, lastMessageIndex), lastMessage];
         newState.loading = true;
         return { ...newState };
      case ATypes.RECEIVE_ANSWERS:
         return { ...state, messages: state.messages.concat(action.payload), activeQuestion: action.payload, loading: false };
      case ATypes.SET_TOKEN:
         return { ...state, token: action.payload };
      case ATypes.FALSE_ANSWER:
         return {
            ...state,
            messages: state.messages.concat(action.payload).concat(state.activeQuestion),
            activeQuestion: state.activeQuestion,
            loading: false
         };
      case ATypes.ASK_FAQ:
         return { ...state, messages: state.messages.concat(action.payload), loading: false };
      case ATypes.SET_NAME:
         return { ...state, name: action.payload }
      case ATypes.SET_LANGUAGE:
         return { ...state, language: action.payload }
      case ATypes.SET_TARGET:
         return { ...state, target: action.payload.target }
      case ATypes.SET_HISTORY:
         return { ...state, history: action.payload, initialized: true }
      case ATypes.SET_MEDIUM:
         return { ...state, medium: action.payload }
      case ATypes.SET_CLIENT_KEY:
         return { ...state, clientKey: action.payload }
      case ATypes.SET_PREVIEW_EXPIRATION_DATE:
         return { ...state, previewExpirationDate: action.payload }
      case ATypes.SET_ERROR:
         debugger;
         return { ...state, error: action.payload }

      default:
         return state;
   }
};
export default reducer;