import envData from "../env";

//braintree
(function () {

    let braintreejs = document.createElement("script");
    braintreejs.src = "https://js.braintreegateway.com/web/dropin/1.11.0/js/dropin.min.js"
    let otherJs = document.getElementsByTagName("script")[0];
    otherJs.parentNode.appendChild(braintreejs)
}())

//facebook setup
window.fbAsyncInit = function () {
    window.FB.init({
        appId: envData.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.12'
    });
};

(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

//google
(function (d, s, id) {
    var js, gjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.onload = function () {
        //GOOGLE SDK LOADED, NOW PERFORM WHATS NEEDED
        window.gapi.load('auth2', function () {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            window.auth2 = window.gapi.auth2.init({
                client_id: envData.REACT_APP_GOOGLE_CLIENT_ID,
                cookiepolicy: 'single_host_origin',
                // Request scopes in addition to 'profile' and 'email'
                //scope: 'additional_scope'

            });
        });
    }
    js.src = "https://apis.google.com/js/platform.js";
    gjs.parentNode.insertBefore(js, gjs);
}(document, 'script', 'geav-google-sdk'))

//WAS:
{/* <script src="https://apis.google.com/js/platform.js"></script> */ }

//mixpanel
// (function () {
//     let mixpanelScriptTag = document.createElement("script");
//     mixpanelScriptTag.type = 'text/javascript';
//     mixpanelScriptTag.text = ``
//     let head = document.getElementsByTagName("head")[0];
//     console.log(mixpanelScriptTag);
//     head.appendChild(mixpanelScriptTag);
// })()

(function (e, a) {
    if (!a.__SV) {
        var b = window;
        try {
            var c, l, i, j = b.location,
                g = j.hash;
            c = function (a, b) {
                return (l = a.match(RegExp(b + "=([^&]*)"))) ? l[1] : null
            };
            if (g && c(g, "state")) {
                i = JSON.parse(decodeURIComponent(c(g, "state")))
                if ("mpeditor" === i.action) {
                    b.sessionStorage.setItem("_mpcehash", g);
                    window.history.replaceState(i.desiredHash || "", e.title, j.pathname + j.search)
                }
            }
        } catch (m) { }
        var k, h;
        window.mixpanel = a;
        a._i = [];
        a.init = function (b, c, f) {
            function e(b, a) {
                var c = a.split(".");
                if (2 == c.length) {
                    b = b[c[0]]
                    a = c[1]
                };
                b[a] = function () {
                    b.push([a].concat(Array.prototype.slice.call(arguments,
                        0)))
                }
            }
            var d = a;
            "undefined" !== typeof f ? d = a[f] = [] : f = "mixpanel";
            d.people = d.people || [];
            d.toString = function (b) {
                var a = "mixpanel";
                "mixpanel" !== f && (a += "." + f);
                b || (a += " (stub)");
                return a
            };
            d.people.toString = function () {
                return d.toString(1) + ".people (stub)"
            };
            k = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
            for (h = 0; h < k.length; h++) e(d, k[h]);
            a._i.push([b, c, f])
        };
        a.__SV = 1.2;
        b = e.createElement("script");
        b.type = "text/javascript";
        b.async = !0;
        b.src = "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL ? window.MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";
        c = e.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(b, c)
    }
})(document, window.mixpanel || []);
window.mixpanel.init(envData.REACT_APP_MIXPANEL_TOKEN);