import React, { Component } from 'react'
import styled from 'styled-components'

export default class TypingEffect extends Component {

    render() {
        return (
            <div class="ticontainer">
                <div class="tiblock">
                    <div class="tidot"></div>
                    <div class="tidot"></div>
                    <div class="tidot"></div>
                </div>
            </div>
        )

    }
}



