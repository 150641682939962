import { isWeb } from "./platformCheck"
import envData from "../env";


export const forgotPassword = (email) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track(
            "FORGOT PASSWORD",
            { email }
        )
    } else {
        // console.mobileWarn("No analytics on mobile");

        console.mobileWarn("No analytics on MyAthensPass")
    }
}

//
export const identify = (userID) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.identify(userID)
    } else {

        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const loginEvent = ({ type, email, isUserUnauth, application, error }) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track(
            "LOGIN",
            { type, email, application, error }
        )
    } else {

    }
}

// REVISIT WITH IDENTIFY/ALIAS ETC
export const registration = ({ type, email, firstName, lastName, error = "no error", isUserUnauth, application, userID }) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track(
            "REGISTRATION",
            { type, email, firstName, lastName, isUserUnauth, application, error }
        )
    } else {

        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const onboardingSuccessEvent = ({ error = "no error", selectedAuth, isUserUnauth, cameHereFrom }) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track(
            "REGISTRATION SUCCESS",
            {
                error,
                selectedAuth,
                isUserUnauth,
                cameHereFrom,
            })
    } else {
        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const newReportEvent = (success, atState, authority_on_novo, closedApp = false, gotInHereFrom, pressedBack = false, issueCategory, authority) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        if (success) incrementReportsMade();

        mixpanel.track("NEW REPORT", {
            atState,
            authority_on_novo,
            closedApp,
            gotInHereFrom,
            pressedBack,
            issueCategory,
            authority,
            success,
        })
    } else {
        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const messageDetailEvent = (cameFrom, otherData, messageId) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track("MESSAGE DETAIL", {
            "cameFrom": cameFrom,
            "otherData": otherData, //its the type
            "messageId": messageId,
        })
    } else {
        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const logoutEvent = () => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.reset();
    } else {
        console.mobileWarn("No analytics on MyAthensPass")
    }
}

export const webChatOpend = (platform, name) => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.track("WEBCHAT_OPENED", {
            "name": name,
        })
    }
}

const incrementReportsMade = () => {
    //GT:WEB_DEP
    const mixpanel = window.mixpanel;

    if (isWeb()) {
        mixpanel.people.increment("reports submitted")
    } else {
        console.mobileWarn("No analytics on MyAthensPass")
    }
}

// const incrementPollsAnswered = () => {
//     mixpanel.people.increment("polls submitted")
// }

// const incrementProfileChanged = () => {
//     mixpanel.people.increment("profile changed")
// }

// const incrementAuthorityChanged = () => {
//     mixpanel.people.increment("authority changed")
// }

// const incrementMobileChanged = () => {
//     mixpanel.people.increment("mobile changed")
// }