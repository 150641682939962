import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { postAnswer } from '../actions';
import { bindActionCreators } from 'redux';
class TextInputContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            textInput: ''
        }
        this.myRef = React.createRef();
    }

    render() {
        return (
            <div onTouchMove={() => { /*document.getElementsByClassName('input')[0].blur()/*document.getElementsByClassName('input').focus()}*/ }}
                onTouchEnd={() => {  document.getElementsByClassName('input')[0].focus(); /*alert(k)/* document.getElementsByClassName('input')[0].focus()  */ }}>
                <StyledForm className="textForm" autoComplete="new-password" onSubmit={this.onFormSubmit}>
                    <StyledInput ref={this.myRef} className='input' autoComplete="off" disabled={this.props.loading} rows="1" placeholder="Type some text" onChange={this.handleChange} name="textInput" value={this.state.textInput} type="text"></StyledInput>
                </StyledForm >
            </div>
        )
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        e.target.value = "";
        if (this.state.textInput.trim() != '') {
            this.props.postAnswer(this.state.textInput);
        }
        this.setState({ textInput: '' })
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.reducer.loading,
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        postAnswer,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TextInputContainer);

const StyledForm = styled.form`

height: 50px;
display:flex
justify-content: center;
padding: 10px;
background-color: whitesmoke;
border-top: 17px;
border-top: 3px solid white;
alignItems:center:
`

const StyledInput = styled.input`
align-self:center;
border-radius: 5px;
width: 80%;
padding: 5px;
border:2px solid rgba(255,255,255,.5);
font-size:1.15em;
-webkit-tap-highlight-color: rgba(255, 255, 255, 0.0);
`