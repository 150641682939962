import React, { Component } from 'react'
import loadingImage from '../novovilleSharedCode/public/images2/other/novovilleLogoBig.png'
import styled from 'styled-components'

export default class LoadingPage extends Component {
    render() {
        return (
            <StyledContainer className="loadingContainer ">
                <img style={{ height: 40, width: 40 }} className="splashAnimation" src={loadingImage} />
            </StyledContainer>
        )
    }
}
const StyledContainer = styled.div`
display:flex;
background-color:whitesmoke;
height:100%;
align-items: center;
justify-content: center;
`;