

export const getItem = (key) => {

    //START:GT:WEB_DEP
    let promise = new Promise((res, rej) => {
        let val = window.localStorage.getItem(key)
        res(val)
    })
    return promise;
    //STOP:GT:WEB_DEP


}

export const setItem = (key, value) => {
    //START:GT:WEB_DEP
    let promise = new Promise((res, rej) => {
        let val = window.localStorage.setItem(key, value)
        res(val);
    })
    return promise;
    //STOP:GT:WEB_DEP

}