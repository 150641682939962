import React, { Component } from 'react';
import './App.css';
import { store } from './Store';
import App from './App';
import { Provider } from 'react-redux';
import { getParamsFromUrl } from './constants/functions';

class AppProvider extends Component {
    render() {

        return (
            <Provider store={store}>
                <App />
            </Provider>
        )
    }
}
export default AppProvider;
getParamsFromUrl();