import React, { Component } from 'react';
import styled from 'styled-components';
import AnswerList from './AnswerList';
import { getDateTime } from '../constants/functions'
import MessageTitle from './MessageTitle';
import PostedAnswer from './PostedAnswer';
import { TYPE_INCOMING } from '../constants/messageTypes';
import { blueColor, greenColor } from "../constants/colors"


export default class Message extends Component {


    render() {
        const messageTime = getDateTime();
        const messageName = this.props.name;
        let { text } = this.props;
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var regex = new RegExp(expression);
        debugger;
        if (text) {


            var contents = text.map((item, index) => {
                if (item.type === "image") {
                    return (
                        <StyledImage key={index} src={item.url} onLoad={this.imageLoaded}></StyledImage>
                    )
                }
                else {
                    let newText = item;
                    let urlArray = item.match(regex);
                    if (urlArray != null) {
                        urlArray.forEach(link => {
                            newText = newText.replace(link, `<a href="${link}" target="_blank"> ${link}</a>`);

                        })
                    }
                    newText = newText.replace(/(\r\n|\n|\r)/gm, "<br />");

                    return (
                        <MessageBody incoming={TYPE_INCOMING} key={index} className="messageBody" dangerouslySetInnerHTML={{ __html: newText }} />
                    )
                }
            });
            return (
                <div className={this.props.className}>
                    <MessageContainer className='messageContainer' incoming={this.props.type} isEmpty={text} >
                        <MessageTitle className="messageTitle" time={messageTime} name={messageName} type={TYPE_INCOMING}>
                        </MessageTitle>
                        {contents}
                        <AnswerList answered={this.props.answered} answers={this.props.answers} />
                    </MessageContainer>
                    <PostedAnswer text={this.props.answered}></PostedAnswer>
                </div>
            )
        }
        else {
            return (<div></div>);
        }
    }
    imageLoaded = () => {
        this.props.imageLoaded();
    }
}

const MessageContainer = styled.div`            
                                display: ${props => props.isEmpty ? "flex" : "none"} ;
                                flex-direction: column;
                                color:white
                                flex-shrink:0;
                                `
const MessageBody = styled.div`
                                padding: 13px;
                                margin-bottom: 10px;
                                border-radius: 7px;
                                margin-left:auto;
                                background-color:${blueColor} ;
                                text-align: right;
`
const StyledImage = styled.img`
                                margin-bottom: 10px;
                                max-width: 42%;
                                border-radius: 7px;
                                text-align: right;
                                align-self: flex-end;
                                flex-shrink: 0;
                                `