import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postAnswer } from '../actions';
import { blueColor, greenColor } from "../constants/colors"

const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

class AnswerContainer extends Component {

    handleClick = (text) => {
        this.props.postAnswer(text)
    }
    render() {
        var className = this.props.enabled ? "disabled" : "enabled";
        return (
            <StyledButton
                onClickCapture={() => {
                    if (!isIos) {
                        this.handleClick(this.props.text)
                    }
                }}
                onTouchEnd={() => this.handleClick(this.props.text)}
                className={className}
                disabled={this.props.enabled}>{this.props.text}
            </StyledButton>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        postAnswer
    }, dispatch);

export default connect(null, mapDispatchToProps)(AnswerContainer);


const StyledButton = styled.div`
    pointer-events:${props => props.disabled ? "none" : "all"} ;
	padding: 8px;
	border-radius: 7px;
	border: 2px solid ${blueColor};
    margin-bottom: 10px;
    margin-left:10px;
    color: ${blueColor};
    flex-shrink:0;
	align-self: center;
    text-align: center;
    min-width:8%;
    width:auto;
    max-width: 30%;
    border-radius: 7px
    &:hover{
        background-color: ${blueColor};
        color: white;
	}
	
`