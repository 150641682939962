
import envdata from "../env"

const isWeb = () => {
    return getPlatform() === "WEB"
}

//the two functions below, isNovoville/isMyAthensPass
//are only relevant on a mobile context, so essentially when isWeb() is false.
//as there is no myAthensPassWebapp


const isNovoville = () => {

    //START:GT:WEB_DEP
    return true;
    //STOP:GT:WEB_DEP
}


const isMyAthensPass = () => {

    //START:GT:WEB_DEP
    return false;
    //STOP:GT:WEB_DEP
}

const isSwiftParking = () => {

    //START:GT:WEB_DEP
    return false;
    //STOP:GT:WEB_DEP
}

//this is used to determine flavor specific features and also used on register/login
const getApplicationName = () => {

    //START:GT:WEB_DEP
    return "web";
    //STOP:GT:WEB_DEP
}

const getAuthorityForCurrentApplication = () => {
    let appName = getApplicationName()
    if (appName === appNames.SWIFTPARKING) {
        return "west_midlands"
    } else if (appName === appNames.MYATHENSPASS) {
        return "athens"
    } else if (appName === appNames.NOVOVILLE) {
        return null;
    } else {
        return null;
    }
}

const getServiceTypeForCurrentApplication = () => {
    let appName = getApplicationName()
    if (appName === appNames.SWIFTPARKING) {
        return "car_parks_parking"
    } else if (appName === appNames.MYATHENSPASS) {
        return "parking"
    } else {
        return "undetermined_error";
    }
}

const getPlatform = () => {
    if (typeof document !== 'undefined') {
        // I'm on the web!
        return "WEB";
    }
    else if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
        // I'm in react-native
        return "RN";
    }
    else {
        return "NODE";
        // I'm in node js
    }
}
const getVersion = () => {
    if (isWeb()) {
        return envdata.REACT_APP_VERSION
    }
    else {
    }

}
const getEnviroment = () => {
    return envdata.ENVIRONMENT
}

function versionToNumber(val) {
    console.log(val)
    return parseInt(val.replace(/[.]/g, ""))
}
const appNames = {
    MYATHENSPASS: "myAthensPass",
    SWIFTPARKING: "swiftPark",
    NOVOVILLE: "novoville",
}

export {
    isWeb,
    isNovoville,
    isMyAthensPass,
    isSwiftParking,
    getApplicationName,
    getAuthorityForCurrentApplication,
    getServiceTypeForCurrentApplication,
    getVersion,
    getEnviroment,
    appNames
};