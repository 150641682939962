import WEBAPI from "../services/WEBAPI.js";
import * as ATypes from "./actionTypes";
import { translateCountry } from "../services/TranslationService"
import { setSelectedCountry } from "../features/authoritySelection/actions"
import { getProfile } from "../features/profile/actions"
import { postAccount } from "../features/register/actions"
import _ from "lodash";
//GT:WEB_DEP
import { push, goBack, replace } from "react-router-redux";

import { setItem } from "../services/localStorageWrap"
import { getInitialParams, getLanguage } from "../services/GNRL";
import { setUUIDOnPushwooshAndStore } from "../features/login/actions"

export const goToAuthorityOrMainApp = () => {
    return (dispatch, getState) => {
        var userAuthAdded = !!getState().profileReducer.profile.authority

        //TODO: MINOR Maybe this should be here, logic wise it makes sense
        //as you will always have registered at this point, and it saves on code
        //but architecturally.. revisit
        return setItem("hasEverRegistered", true)
            .then(() => {
                if (userAuthAdded) {
                    dispatch(goToMainApp());
                } else {
                    dispatch(goToAuthoritySelection());
                }
            })
    };
}

export const setLanguage = (language) => {
    return (dispatch, getState) => {
        dispatch({
            type: ATypes.SET_SELECTED_LANGUAGE,
            payload: language
        })
        return dispatch(getTranslations);
    };
}
export const setInternetStatus = (hasInternet) => {
    return (dispatch, getState) => {
        dispatch({
            type: ATypes.SET_INTERNET_STATUS,
            payload: hasInternet
        })
    };
}


export const goToAuthoritySelection = (withData) => {
    return (dispatch, getState) => {
        dispatch(push("/authoritySelection", withData));
    };
};

export const goToAddMobile = (withData) => {
    return (dispatch, getState) => {
        dispatch(push("/addPhone", withData));
    };
};

export const goToVerifyEmail = (withData) => {
    return (dispatch, getState) => {
        dispatch(push("/verifyEmail", withData));
    };
};



export const verifyRequest = (mobileOrEmail, application) => {
    return (dispatch, getState) => {
        dispatch({
            type: ATypes.POST_VERIFY_REQ_REQ
        })

        return WEBAPI.verifyRequest(mobileOrEmail, application, getLanguage())
            .then(() => {
                dispatch({
                    type: ATypes.POST_VERIFY_REQ_RES
                })
            })
    };
};

export const postError = (type, err) => {
    return (dispatch, getState) => {
        let state = getState()
        let error = _.get(err, 'details.user_description', "UNKOWN_ERROR")
        let token = _.get(state, "authReducer.auth_token")
        let data = { error, type }
        return WEBAPI.postError(data, token)
    }
}

export const goToMainApp = () => {

    //START:GT:WEB_DEP
    return (dispatch, getState) => {
        dispatch(push("/home"));
        // dispatch(push("/phonebook"));
    };
    //STOP:GT:WEB_DEP
};

export const goTo = (page, urlData, reducerData) => {
    console.log(reducerData)
    return (dispatch, getState) => {

        page = "/" + page
        if (urlData) {
            page = page + "/" + urlData
        }

        dispatch(push(page, reducerData));
    };
}

export const replaceRouter = (page, urlData, withData) => {
    return (dispatch, getState) => {

        page = "/" + page
        if (urlData) {
            page = page + "/" + urlData
        }

        dispatch(replace(page, withData, { "KEKEKE": "kokoko" }));
    };
}

export const goBackRouter = (withData) => {
    return (dispatch, getState) => {
        dispatch({
            type: "GOBACKDATASTORE",
            payload: withData
        })
        dispatch(goBack());
    };
};

const sleep = ms => new Promise(res => setTimeout(res, ms));

//BOOTSTRAPING ACTIONS

export const getSystemStatus = dispatch => {
    dispatch({
        type: ATypes.GET_SYSTEM_STATUS
    });

    return WEBAPI.getSystemStatus()
        .then(res => {

            dispatch({
                type: ATypes.SET_FORGOT_PASS_URL,
                payload: res
            });
            return res;
        })
}

export const getTranslations = (dispatch, getState) => {
    dispatch({
        type: ATypes.GET_TRANSLATIONS_REQUEST
    });
    let tempLang = getLanguage()
    let language = _.get(getState(), "settingsReducer.selectedLanguage", tempLang)
    return WEBAPI.getTranslations(language)
        .then(res => {

            dispatch({
                type: ATypes.GET_TRANSLATIONS_RESPONSE,
                payload: res
            });
            return res;
        })
};

export const getCountries = dispatch => {
    dispatch({
        type: ATypes.GET_COUNTRIES_REQUEST
    });

    return WEBAPI.getCountries()
        .then(res => {
            const countries = Object.keys(res.result)
                .map(key => res.result[key])
                .map(country => {
                    country.translatedName = translateCountry(country.name);
                    return country
                });

            dispatch({
                type: ATypes.GET_COUNTRIES_RESPONSE,
                payload: countries
            });
            // dispatch(push("/login"));
            return countries;
        })
};

export const getModules = () => {
    return (dispatch, getState) => {

        let myAuthName = _.get(getState(), "profileReducer.profile.authority.name")
        if (!myAuthName) return Promise.resolve();

        dispatch({
            type: ATypes.GET_MODULES_REQ
        });

        return WEBAPI.getModules(myAuthName)
            .then(res => {
                dispatch({
                    type: ATypes.GET_MODULES_RES,
                    payload: res.result
                });
            })
    }
}

export const initialProcess = () => {
    return (dispatch, getState) => {
        let systemStatusResponse;
        return dispatch(getSystemStatus)
            .then((res) => {
                systemStatusResponse = res;
                var paramObj = getInitialParams();
                if (paramObj.displayMessageAuthority) {
                    paramObj["setAuthority"] = paramObj.displayMessageAuthority
                    // TODO: Authority alone isnt enough, we assume greece now because
                    //the people in athens couldnt change the url but we need to askthem
                    //to change it as country is also needed when setting an authority to a profile
                    paramObj["setCountry"] = paramObj.displayMessageCountry ? paramObj.displayMessageCountry : "el"
                    paramObj["goToPage"] = "home"
                }
                return dispatch(setOriginalUrlParameters(paramObj))
            })
            .then(() => {
                return dispatch(getTranslations);
            })
            .then(() => {
                return dispatch(getCountries);
            })
            .then(() => {
                if (getState().authReducer.auth_token) {
                    return dispatch(getProfile())
                        .then(() => {
                            //we dont need to wait for post acc

                            let promise = dispatch(postAccount())


                            return dispatch(getModules())
                        })
                }
            })
            .then(() => {
                return dispatch(setSelectedCountry());
            })
            .then(() => {
                //this is used as a minimum by the parking mobile app.
                return systemStatusResponse;
            })
    };
}

export const setOriginalUrlParameters = (originalUrlParams) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ATypes.SET_ORIGINAL_URL_PARAMS,
            payload: originalUrlParams
        })
    };
}

export const unsetOriginalUrlParameters = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: ATypes.UNSET_ORIGINAL_URL_PARAMS
        })
    };
}

export const refreshToken = () => {
    return (dispatch, getState) => {
        return WEBAPI.refreshToken()
            .then(res => {
                dispatch({
                    type: ATypes.REFRESH_TOKEN_RES,
                    payload: res.result
                });
            })
    }
}

//PARKING RELATED, NO FEATURE PARKING TO ADD THESE ACTIONS
//TO DIRECTLY RIGHT NOW AS ITS ONLY USED BY MYATHENSPASS

export const getTickets = (authority) => {
    return (dispatch, getState) => {
        return WEBAPI.getTickets(authority)
            .then(res => {
                dispatch({
                    type: ATypes.GET_TICKETS_RES,
                    payload: res.result,
                })
                return res;
            })
    }
}

export const getTicketsCARPARKS = (authority) => {
    return (dispatch, getState) => {
        return WEBAPI.getTicketsCARPARKS(authority)
            .then(res => {
                dispatch({
                    type: ATypes.GET_TICKETS_RES,
                    payload: res.result,
                })
                return res;
            })
    }
}

export const activateTicket = (data, authority) => {
    return (dispatch) => {
        return WEBAPI.activateTicket(data, authority)
            .then(res => {
                dispatch({
                    type: "POST_ACTIVATE_TICKET_RES",
                })
            })
    }
}

export const activateTicketCARPARKS = (data, authority, carParkId) => {
    return (dispatch) => {
        return WEBAPI.activateTicketCARPARKS(data, authority, carParkId)
            .then(res => {
                dispatch({
                    type: "POST_ACTIVATE_TICKET_RES",
                })
            })
    }
}

export const extendTicket = (data, authority) => {
    return (dispatch) => {
        return WEBAPI.extendTicket(data, authority)
            .then(res => {
                dispatch({
                    type: "POST_EXTEND_TICKET_RES",
                })
            })
    }
}

export const extendTicketCARPARKS = (data, authority, carParkId) => {
    return (dispatch) => {
        return WEBAPI.extendTicketCARPARKS(data, authority, carParkId)
            .then(res => {
                dispatch({
                    type: "POST_EXTEND_TICKET_RES",
                })
            })
    }
}

export const cancelActiveTicket = (authority) => {
    return (dispatch, getState) => {
        return WEBAPI.cancelActiveParkingTicket(authority)
            .then(() => {
                dispatch({
                    type: "PUT_CANCEL_ACTIVE_PARKING_TICKET",
                })
            })
    }
}

export const cancelActiveTicketCARPARKS = (authority, carParkId) => {
    return (dispatch, getState) => {
        return WEBAPI.cancelActiveParkingTicketCARPARKS(authority, carParkId)
            .then(() => {
                dispatch({
                    type: "PUT_CANCEL_ACTIVE_PARKING_TICKET",
                })
            })
    }
}

export const getGeozones = (authority) => {
    return (dispatch, getState) => {

        let myAuthName = authority || _.get(getState(), "profileReducer.profile.authority.name")
        if (!myAuthName) return Promise.resolve();

        dispatch({
            type: ATypes.GET_GEOZONES_REQ
        });

        return WEBAPI.getGeozones(myAuthName)
            .then(res => {
                dispatch({
                    type: ATypes.GET_GEOZONES_RES,
                    payload: res.result
                });
            })
    }
}


//authority
//timestamp. we use this to check in the future. it is used for parking extension
//if no timestamp then we're not checking based on extension
export const getParkingAvailability = (authority, timestamp) => {
    return (dispatch) => {
        return WEBAPI.getParkingAvailability(authority, timestamp)
            .then((res) => {
                console.log("returning from web")
                if (!timestamp) {
                    dispatch({
                        type: "GET_PARKING_AVAILABILITY_RES",
                        payload: res.result
                    })
                }
                return res.result;
            })
        // }
    }
}

export const getCarParks = (authority) => {
    return (dispatch) => {
        return WEBAPI.getCarParks(authority)
            .then((res) => {
                debugger;
                dispatch({
                    type: "GET_CAR_PARKS_RES",
                    payload: res.result
                })

                return res.result;
            })
        // }
    }
}