import React, { Component } from 'react';
import AnswerContainer from './AnswerContainer';

class AnswerList extends Component {

    render() {
        debugger;
        let contents
        if (this.props.answers) {
            contents = this.props.answers.map(item =>
                <AnswerContainer key={item} enabled={this.props.answered} text={item} />)

        }
        return (
            <div className="AnswerList">
                {contents}
            </div>
        )
    }
}
export default AnswerList