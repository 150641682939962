import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import reducer from './reducers'
import { authReducer } from "./novovilleSharedCode/src/features/register/reducers";
import { translationsReducer } from "./novovilleSharedCode/src/shared/reducers"

let reducersToCombine = {
    reducer,
    authReducer,
    translationsReducer
}
window.getAuthToken = () => {
    return store.getState().authReducer.auth_token;
};
var combinedReducers = combineReducers(reducersToCombine);
export const store = createStore(
    combinedReducers,
    applyMiddleware(thunk, logger)
)
window.store = store;