import envData from "../env";
// import { getAuthToken, getSelectedCountry } from "./store";
import { translateAPICode, translate } from "./TranslationService"
import { getLanguage, getPlatformData, getDeviceType } from "../services/GNRL";
import {
  isWeb,
  isMyAthensPass,
  isSwiftParking,
  isNovoville,
  getApplicationName,
} from "./platformCheck";

import _ from "lodash";

// !!! TODO: REMOVE THESE ITS UGLY AS SIN AND STORE SHOULDNT BE EXPOSING THIS
// !!! ONLY KEEPING IT HERE TO TALK ABOUT THIS WITH SOMEONE
// import { dispatch } from "./store"
import * as ATypes from "../shared/actionTypes";

//GT:WEB_DEP
var Platform = undefined

//START:GT:WEB_DEP
const { dispatch } = window.store
//TODO: make logout process an action also in web
const { getAuthToken, getSelectedCountry, logout = () => { } } = window;
//STOP:GT:WEB_DEP


let BASE_URL_APP = envData.REACT_APP_BASE_URL_APP;
let BASE_URL_SSO = envData.REACT_APP_BASE_URL_SSO;

// TODO: THIS DOESNT NEED TO BE A CLASS, MAKE IT INTO FUNCTIONS INSTEAD

//adds bundle id, type of app, if its web etc
function createMetadataHeader() {
  let data;
  if (isWeb()) {
    data = "web"
  } else {
    if (isMyAthensPass()) {
      data = "myathenspass"
    }
    if (isSwiftParking()) {
      data = "oneapp"
    }
    if (isNovoville()) {
      data = "novoville"
    }
  }
  console.log("header to send:")
  console.log(data)
  data += "," + getDeviceType();
  return data;
}

class WEBAPI {
  // constructor() {
  //   //TOASK SHOULD THE CLASS BE LIKE THIS OR A SINGLETON AND ADDING THE BASEURL IN THE CONSTRUCTOR?
  // }

  static createHeaders() {
    let headersObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0,
      'ClientNOVOData': createMetadataHeader(),
    }
    const token = getAuthToken();
    if (token) headersObj["Authorization"] = `Bearer ${token}`;
    return headersObj;
  }

  static createHeadersURLFormEncoded() {
    let headersObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'ClientNOVOData': createMetadataHeader(),
    };
    const token = getAuthToken();
    if (token) headersObj["Authorization"] = `Bearer ${token}`;
    return headersObj;
  }

  static createHeadersMultipart() {
    let headersObj = {
      Accept: "application/json",
      'ClientNOVOData': createMetadataHeader(),
      // "Content-Type": "multipart/form-data",
    };
    const token = getAuthToken();
    if (token) headersObj["Authorization"] = `Bearer ${token}`;
    return headersObj;
  }

  static constructURL(url, serviceName, baseURL) {
    var newURL = "";
    if (!baseURL) {
      if (serviceName === "SSO") {
        newURL = this.baseUrlSSO + url;
      } else {
        newURL = this.baseUrlAPP + url;
      }

      return newURL;
    } else {
      return baseURL;
    }
  }

  static getURL(url, serviceName, baseURL, mockData) {

    if (mockData) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          res(mockData)
        }, 3000)
      })
    }

    // if (url === "tickets/athens") {
    //   return (
    //     Promise.resolve({
    //       "error": false,
    //       "http_response": 200,
    //       "result": {
    //         "id": 3,
    //         "license_plate": "",
    //         "remaining_credits": 60,
    //         "start_at": "0000-00-00 00:00:00",
    //         "end_at": "0000-00-00 00:00:00",
    //         "history": [
    //           {
    //             "type": "purchase",
    //             "form_id": 4,
    //             "credits": 4,
    //             "invoice_url": "http://192.168.2.33:2222/files/invoices/invoice_3_5bec01aa8f578_1542193578.pdf",
    //             "created_at": "2018-11-14 13:06:18"
    //           },
    //           {
    //             "created_at": "2018-10-31 16:42:29",
    //             "end_at": "2018-10-31 16:57:29",
    //             "license_plate": "XXX",
    //             "minutes": 15,
    //             "credits": 2,
    //             "start_at": "2018-10-31 16:42:29",
    //             "type": "activation"
    //           }
    //         ],
    //         "pricing": [
    //           {
    //             minutes: 30,
    //             cost: 5
    //           },
    //           {
    //             minutes: 60,
    //             cost: 10
    //           },
    //           {
    //             minutes: 90,
    //             cost: 10
    //           },
    //         ],
    //         "created_at": "2018-11-14 13:06:18",
    //         "updated_at": "2018-11-14 13:06:18",
    //         "authority_name": "athens",
    //         "is_active": false,
    //         "remaining_parking_seconds": 3
    //       }
    //     })

    //   )
    // }

    var constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);

    var headers = null;
    // if (baseURL) {
    //   headers = new Headers({
    //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    //     'Cache-Control': 'no-cache, no-store, must-revalidate',
    //     'Pragma': 'no-cache',
    //     'Expires': 0
    //   });
    // } else {
    headers = this.createHeaders();
    // }

    var that = this;
    if (that.enableLogging) {
      console.log("getting url " + constructedURL);
    }

    var fetchChain = fetch(constructedURL, {
      method: "GET",
      headers: headers
    })
      .then(response => {
        checkResponseStatus(response)
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log("get url " + constructedURL + " response");
          console.log(jsonData);
        }

        if (jsonData["error"] === true) {
          if (jsonData["code"] === "109") {
            return (
              WEBAPI.refreshToken()
                .then((res) => {
                  return WEBAPI.getURL(url, serviceName, baseURL)
                })
            )
          } else if (jsonData["code"] === "108") {
            if (logout) logout();
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        } else {
          return jsonData;
        }
      })
      .catch(err => {
        //here it could be that the error comes from our API, or that something happened with fetch. if so
        if (!_.get(err, "details.user_description")) {
          err.details = { ...err.details, user_description: translate("OTHER.errorOcurred") }
        }
        console.log("ERROR IN GET");
        console.log(err);
        throw err;
      });

    return fetchChain;
  }

  static postURL(url, data, serviceName, baseURL, mockData) {

    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData)
      })
    }

    var constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    var headers = this.createHeadersURLFormEncoded();
    var that = this;
    if (that.enableLogging) {
      console.log("post url " + constructedURL);
      console.log("with data");
      console.log(data);
      // console.log(JSON.stringify(data));
    }

    return fetch(constructedURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data) //TODO is this required?
      // body: data //TODO is this required?
    })
      .then(response => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log("post url " + constructedURL + " response");
          console.log(jsonData);
        }

        if (jsonData["error"] === true) {
          if (jsonData["code"] === "109") {
            return (
              WEBAPI.refreshToken()
                .then((res) => {
                  return WEBAPI.postURL(url, data, serviceName, baseURL)
                })
            )
          } else if (jsonData["code"] === "108") {
            if (logout) logout();
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch(err => {
        if (!_.get(err, "details.user_description")) {
          err.details = { ...err.details, user_description: translate("OTHER.errorOcurred") }
        }
        console.log("ERROR IN POSTING");
        console.log(err);

        throw err;
      });
  }

  static putURL(url, data, serviceName, baseURL, mockData) {

    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData)
      })
    }

    var constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    var headers = this.createHeadersURLFormEncoded();
    var that = this;
    if (that.enableLogging) {
      console.log("put url " + constructedURL);
      console.log("with data");
      console.log(data);
      // console.log(JSON.stringify(data));
    }

    return fetch(constructedURL, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data) //TODO is this required?
      // body: data //TODO is this required?
    })
      .then(response => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log("put url " + constructedURL + " response");
          console.log(jsonData);
        }

        if (jsonData["error"] === true) {
          if (jsonData["code"] === "109") {
            return (
              WEBAPI.refreshToken()
                .then((res) => {
                  return WEBAPI.postURL(url, data, serviceName, baseURL)
                })
            )
          } else if (jsonData["code"] === "108") {
            if (logout) logout();
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch(err => {
        if (!_.get(err, "details.user_description")) {
          err.details = { ...err.details, user_description: translate("OTHER.errorOcurred") }
        }
        console.log("ERROR IN PUT-TING");
        console.log(err);

        throw err;
      });
  }

  static postURLMultipart(url, data, serviceName, baseURL, mockData) {

    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData)
      })
    }

    var constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    var headers = this.createHeadersMultipart();
    var that = this;
    if (that.enableLogging) {
      console.log("post url " + constructedURL);
      console.log("with data");
      console.log(data);
    }

    var formData = new FormData();

    for (var name in data) {
      console.log(name, " ", data[name])

      if (data[name]) {
        formData.append(name, data[name]);
      }
    }

    return fetch(constructedURL, {
      method: "POST",
      headers: headers,
      body: formData
    })
      .then(response => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log("post url " + constructedURL + " response");
          console.log(jsonData);
        }

        if (jsonData["error"] === true) {
          if (jsonData["code"] === "109") {
            return (
              WEBAPI.refreshToken()
                .then((res) => {
                  return WEBAPI.postURLMultipart(url, data, serviceName, baseURL)
                })
            )
          } else if (jsonData["code"] === "108") {
            if (logout) logout();
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            var err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }
        return jsonData;
      })
      .catch(err => {
        if (!_.get(err, "details.user_description")) {
          err.details = { ...err.details, user_description: translate("OTHER.errorOcurred") }
        }
        console.log("ERROR IN POSTING MULTIPART");
        console.log(err);

        throw err;
      });
  }

  //#endregion
  //#region REFRESH TOKEN

  static refreshToken() {
    return this.getURL("auth/refresh_token", "SSO", null).then(res => {
      return dispatch({
        type: ATypes.REFRESH_TOKEN_RES,
        data: res.result.token
      })
    })
  }

  //#endregion
  //#region SPECIFIC ROUTES

  static getCountries() {
    return this.getURL("countries", "SSO", null).then(jsonData => {
      return jsonData;
    });
  }

  static getSystemStatus() {
    return this.getURL("system", "SSO", null).then(jsonData => {
      if (jsonData["result"]["system_status"] !== "NORMAL") {
        var err = Error("SYSTEM STATUS ERROR");
        err.details = {
          user_description: translate("OTHER.SYSTEM_MAINTENANCE")
        }
        throw err;
      }

      let forgotPassUrl = jsonData["result"]["forgot_password_url"];

      return this.getURL("system", "NOVOVILLE", null).then(jsonData => {
        if (jsonData["result"]["system_status"] !== "NORMAL") {
          var err = Error("SYSTEM STATUS ERROR");
          err.details = {
            user_description: translate("OTHER.SYSTEM_MAINTENANCE")
          }
          throw err;
        }

        let parkingFinesURL = jsonData["result"]["parking_app_web_payment_endpoint"]
        let minParkingCitizenAppVersion = jsonData["result"]["min_parking_citizen_app_version"]
        let minAppVersion = jsonData["result"]["min_app_version"]

        return {
          forgotPassUrl,
          parkingFinesURL,
          minParkingCitizenAppVersion,
          minAppVersion,
        };
      })
    })
  }

  static getTranslations(language) {

    var currentlang = language ? language : getLanguage();
    currentlang = currentlang == "gr" ? "el" : currentlang
    return this.getURL("system/translations" + "/" + currentlang, "SSO", null)
      .then(translationDataSSO => {
        return translationDataSSO;
      })
      .then(translationDataSSO => {
        return this.getURL("system/translations" + "/" + currentlang, "NOVOVILLE").then(
          translationDataNOVOVILLE => {
            var responses = {
              ...translationDataSSO.result.responses,
              ...translationDataNOVOVILLE.result.responses
            };
            var allTranslationsData = {
              ...translationDataSSO.result,
              ...translationDataNOVOVILLE.result,
              responses
            };
            return allTranslationsData;
          }
        );
      });
  }

  static postRegister(email, password, name, surname, application, skipRegistration) {
    var payload = {
      email,
      password,
      name,
      surname
    };
    if (application) payload["application"] = application;
    if (skipRegistration) payload["skip_registration"] = 1;

    return this.postURL("auth/register", payload, "SSO", null);
  }

  static postLogin(email, password, social_token, name, surname, social_type, application) {
    var payload = {
      email,
      password,
      device_type: Platform ? (Platform.OS === "ios" ? "ios" : "android") : "webapp",
    };

    //social login logic
    if (social_token) {
      payload["social_token"] = social_token
    }

    if (name) {
      payload["name"] = name;
    }

    if (surname) {
      payload["surname"] = surname;
    }

    if (social_type) {
      payload["social_type"] = social_type;
    }

    if (application) {
      payload["application"] = application;
    }

    return this.postURL("auth/login", payload, "SSO", null)
  }

  static postAccount() {
    let device_id = "error_unspecified";
    //GT:WEB_DEP
    device_id = "browser";

    debugger;
    let data = {
      device_id,
      device_type: getDeviceType(),
      device_version: getPlatformData().name,
      os_version: getPlatformData().os.family,
      notifications: true
    };
    console.log("data will be send by post account")
    console.log(data)
    return this.postURL("accounts", data, "SSO", null);
  }

  static getProfile() {
    return this.getURL("profiles", "SSO", null)
  }

  static postProfile(profileObject) {
    let data = profileObject;
    //TOASK WHOSE JOB IS IT TO ENHANCE THE PROFILE WITH THE REST OF THE FIELDS
    //ASSUMING THE APY DOESNT HANDLE THEIR ABSENCE WELL?
    return this.postURLMultipart("profiles", data, "SSO", null);
  }

  static getAllAuthoritiesForCountry(countryCode) {
    var postFix = countryCode ? "/" + countryCode : "";

    return this.getURL("authorities" + postFix, "SSO", null).then(jsonData => {
      return jsonData;
    });
  }

  static getMessages(authorityName, messageId, typesArray) {
    let messagesUrl;
    if (authorityName && messageId) {
      //to get a specific message you also need to specify the authority other than its id
      messagesUrl = `messages/${authorityName}/${messageId}`;
    } else if (authorityName) {
      //this will return all messages for this specific authority
      messagesUrl = `messages/${authorityName}`
    } else {
      //this will return all messages for your authority
      messagesUrl = "messages"
    }

    //if there are type restrictions add them
    if (typesArray) {
      messagesUrl += `?types=${typesArray.join(",")}`
    }

    return this.getURL(messagesUrl, "NOVOVILLE", null)
  }

  //these are my reports
  static getReports(authorityName, reportId) {
    if (authorityName && reportId) {
      //to get a specific report you also need to specify the authority other than its id
      let postFix = "/" + reportId + "/" + authorityName;
      return this.getURL(`reports${postFix}`, "NOVOVILLE", null);
    } else {
      //this will return all reports for your authority
      return this.getURL("reports", "NOVOVILLE", null);
    }
  }

  //these are the authority reports ( includes other users )
  static getReportsFromAuthority(authorityName) {
    return this.getURL(`reports/authorities/${authorityName}`, "NOVOVILLE", null);
  }

  static postReport(report) {
    // ["country"  "authority":  "address": "longitude": "latitude":  AnyObject, "issue":  "description": reAnyObject, "urgent":  
    return this.postURLMultipart("reports", report, "NOVOVILLE", null)
  }

  static postPoll({ message_id, answer, authority }) {
    // return this.postURL("polls", { message_id, answer }, "NOVOVILLE", null);
    return this.postURLMultipart("polls", { message_id, answer: JSON.stringify(answer), authority }, "NOVOVILLE", null);
  }

  static getFAQS() {
    return this.getURL("faqs", "NOVOVILLE", null);
  }

  static getPhones(authorityId) {

    var postFix = authorityId ? "/" + authorityId : "";
    return this.getURL("phones" + postFix, "NOVOVILLE", null);
  }

  static getMoreMessages(url) {
    return this.getURL(null, null, url);
  }

  static verifyRequest(mobileOrEmail, application, language = "en") {
    const data = {}

    if (mobileOrEmail.indexOf("@") !== -1) {
      data["email"] = mobileOrEmail
    } else {
      data["mobile"] = mobileOrEmail
    }

    // data["language"] = getSelectedCountry();
    data["language"] = language;
    if (application) data["application"] = application;

    return this.postURL("verifications/verify_request", data, "SSO", null);
  }

  static verify(mobileOrEmail, token) {

    var createdRoute = "verifications/verify?"

    if (mobileOrEmail.indexOf("@") !== -1) {
      createdRoute = encodeURIComponent(createdRoute + "email=" + mobileOrEmail + "&token=" + token)
    } else {
      createdRoute = createdRoute + "mobile=" + mobileOrEmail.replace("+", "") + "&token=" + token
    }

    return this.getURL(createdRoute, "SSO", null);
  }

  static checkIfVerified(mobileOrEmail) {

    let parameter;
    if (mobileOrEmail.indexOf("@") !== -1) {
      parameter = { email: mobileOrEmail }
    } else {
      parameter = { mobile: mobileOrEmail }
    }
    return this.postURL("verifications/verified", parameter, "SSO")
  }

  static getPOISFromAuthority(authorityId) {
    var postFix = authorityId ? "/" + authorityId : "";
    postFix += "?types=all"
    return this.getURL("pois" + postFix, "NOVOVILLE", null);
  }

  static getIssues(authority) {
    console.log(authority);
    var postFix = authority.authority ? "/" + authority.authority : authority ? `/${authority}` : "";
    return this.getURL("issues" + postFix, "NOVOVILLE", null)
      .then(res => {
        return Object.keys(res.result).map(key => { return { name: res.result[key], translatedName: res.result[key] } })
      });
  }

  static getHomeData(num) {
    return this.getURL(`home/${num}`, "NOVOVILLE", null);
  }

  static getStatistics() {
    return this.getURL("statistics", "NOVOVILLE", null)
  }

  static getCoordinatesForAuthority(authority, country) {
    return this.postURL("geocoding/geocode", { authority, country }, "NOVOVILLE", null);
  }

  static reverseGeocode(latitude, longitude) {
    return this.postURL("geocoding/reverse_geocode", { latitude, longitude }, "NOVOVILLE", null);
  }

  static getLocationForPlaceID(placeId) {
    // let key = envData.REACT_APP_GOOGLE_MAP_TOKEN;

    var request = {
      placeId,
      fields: ['formatted_address', 'geometry']
    };

    return new Promise((res, rej) => {
      let service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(request, (place, status) => {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej();
        }
      });
    })
  }

  static getForms(id) {
    if (id) {
      id = "/" + id
    } else {
      id = ""
    }

    return this.getURL("forms" + id, "NOVOVILLE", null)
  }

  static getFineDetails(finePaymentCode) {
    return this.getURL("forms/fine/" + finePaymentCode);
  }

  static getFormsForAuthority(authority, serviceType) {
    return this.getURL(`forms/supported/${authority}${serviceType ? "?service_type=" + serviceType : ""}`, "NOVOVILLE", null)
      .then(res => {
        return res.result;
        // let result = res.result;
        // let formattedData = Object.keys(result).map(key => {
        //   return {
        //     ...result[key],
        //     type: key,
        //   }
        // })
        // return formattedData;
      })
  }

  static postForm(authority, type, data) {
    return this.postURL(`forms/${authority}`, { type, data })
  }

  static postPayment(data, authority) {
    return this.postURL(`forms/${authority}/payment`, data)
  }

  static getBraintreeToken() {
    return this.getURL("forms/braintree_token")
  }

  static getModules(authorityName) {
    return this.getURL("system/modules/" + authorityName, "NOVOVILLE", null)
  }

  static getCalendar() {
    return this.getURL("calendar", "NOVOVILLE", null);
  }

  static subscribeToPOI(data) {
    return this.postURL(`pois/subscribe`, data)
  }

  static unsubscribeFromPOI(data) {
    return this.postURL(`pois/unsubscribe`, data)
  }

  static postError(data, token) {
    debugger;
    if (token) {
      return this.postURL('system/errors', data, "NOVOVILLE", null, null)
    }
    else {
      return this.postURL('system/errors_anonymous', data, "NOVOVILLE", null, null)
    }
  }
  //#endregion
  //#region PARKING RELATED
  static getTickets = (authority) => {
    return WEBAPI.getURL(`tickets/${authority}`, "NOVOVILLE");
  }

  static getTicketsCARPARKS = (authority) => {
    return WEBAPI.getURL(`${authority}/car_parks/tickets`, "NOVOVILLE");
  }

  static activateTicket = (data, authority) => {
    return WEBAPI.postURL(`tickets/${authority}`, data, "NOVOVILLE");
  }

  static activateTicketCARPARKS = (data, authority, carParkId) => {
    return WEBAPI.postURL(`${authority}/car_parks/${carParkId}/tickets`, data, "NOVOVILLE");
  }

  static extendTicket = (data, authority) => {
    return WEBAPI.postURL(`tickets/${authority}/extension`, data, "NOVOVILLE");
  }

  static extendTicketCARPARKS = (data, authority, carParkId) => {
    return WEBAPI.postURL(`${authority}/car_parks/${carParkId}/tickets/extension`, data, "NOVOVILLE");
  }

  static cancelActiveParkingTicket = (authority) => {
    return WEBAPI.putURL(`tickets/${authority}/cancel`, undefined, "NOVOVILLE")
  }

  static cancelActiveParkingTicketCARPARKS = (authority, carParkId) => {
    return WEBAPI.putURL(`${authority}/car_parks/${carParkId}/tickets/cancel`, undefined, "NOVOVILLE")
  }

  static getParkingAvailability = (authority, timestamp) => {

    return WEBAPI.getURL(`tickets/${authority}/parking/availability${timestamp ? "/" + timestamp : ""}`, "NOVOVILLE")

    // return Promise.resolve({
    //   result: {
    //     can_park_now: true,
    //     can_park_for: 23,
    //     this_weeks_schedule: {
    //       0: { start: "14:00", end: "21:00" },
    //       1: { start: "9:00", end: "21:00" },
    //       2: { start: "9:00", end: "21:00" },
    //       3: { start: "9:00", end: "21:00" },
    //       4: { start: "12:00", end: "21:00" },
    //       5: { start: "13:00", end: "21:00" },
    //       6: { start: "14:00", end: "21:00" },
    //     }
    //   }
    // })
  }

  static getCarParks = (authority) => {
    return WEBAPI.getURL(
      `${authority}/car_parks`,
      "NOVOVILLE",
      null,
      // getDummyMarkersAround(37.976251, 23.729498)
    )
  }

  static checkParkingZone = (data, authority) => {
    return WEBAPI.postURL(`tickets/${authority}/check_parking_zone`, data, "NOVOVILLE")
  }
  static getGeozones = (authority) => {
    return WEBAPI.getURL(`tickets/${authority}/parking/zones`, "NOVOVILLE")
  }

  //#endregion


  //#region WALLET RELATED
  static getWallet = (authority) => {
    return WEBAPI.getURL(`wallet/${authority}`, "NOVOVILLE");
  }

  static getTransactions = (authority, typesArray, serviceType) => {
    let types = ''
    if (typesArray) {
      types += `&types=${typesArray.join(",")}`
    }
    return WEBAPI.getURL(`wallet/${authority}/transactions${serviceType ? `?service_type=${serviceType}` : ''}${types}`, "NOVOVILLE");
  }
  static getMoreWalletTransactions = (url) => {
    debugger;
    return WEBAPI.getURL(null, null, url);
  }

  static getWalletTopupOptions = (authority, serviceType) => {
    return WEBAPI.getURL(`wallet/${authority}/topup_options/${serviceType}`, "NOVOVILLE");
  }

  static topupWallet = (authority, data) => {
    //data is 
    //amount:50
    //card_id:2
    //service_type:parking
    return WEBAPI.postURL(`wallet/${authority}/topup`, data, "NOVOVILLE");
  }
  //#endregion
  //#region CARDS
  static getCreditCardTokens = ({ application } = {}) => {
    return WEBAPI.getURL(`cards${application ? "?application=" + application : ""}`, "SSO");
  }

  static deactivateCard = (cardId) => {
    return WEBAPI.putURL(`cards/${cardId}`, null, "SSO")
  }

  static getRedirectPaymentPage = "/v1/cards/redirect_page";
  //#endregion
  //#region WEBCHAT

  static getDiscussions = (authorityName) => {
    return WEBAPI.getURL(`discussions/${authorityName}`, "NOVOVILLE", null);
  }

  static postAnswer = (name, said, token, language, target, medium, client_key, preview_expiration_date) => {

    var data = { name, said, medium, "user_token": token, language, client_key, preview_expiration_date };

    return WEBAPI.postURL(`discussions/${target}/${medium}`, data, "NOVOVILLE", null, null)
    //var fingerpirnt=fingerprint;
    //return WEBAPI.delay(5000).then(() => { return WEBAPI.postURL(url, answer, serviceName, baseURL, mockData) })
  }
  //#endregion
}

function checkResponseStatus(response) {
  if (response.status >= 500) {
    console.log(response)
    var err = Error("Server response not ok.");
    err.details = {
      response: response.status,
      user_description: translate("OTHER.errorOcurred")
    };
    throw err;
  }
}

function serviceAndCodeToTranslatable(serviceName, apiCode) {
  if (serviceName === "SSO") {
    return "ssoapi_" + apiCode;
  } else {
    return "api_" + apiCode;
  }
}

function createWEBAPIError(serviceName, jsonData) {
  let err = new Error("API Error")
  let user_description = translateAPICode(serviceAndCodeToTranslatable(serviceName, jsonData["code"]))
  let dynamicTranslationArgs = jsonData["args"]

  if (dynamicTranslationArgs) {
    Object.keys(dynamicTranslationArgs).forEach(key => {
      var regex = new RegExp(key, "g");
      user_description = user_description.replace(regex, dynamicTranslationArgs[key])
    })
  }


  err.details = {
    error_code: jsonData["code"],
    error_result: jsonData["result"],
    user_description,
  };
  return err;

}


//DUMMY DATA

function getDummyMarkersAround(lat, lng) {

  let numOfMarkers = 10;
  let markerDataArr = []
  for (var i = 0; i < Math.floor(Math.sqrt(numOfMarkers)); i++) {
    for (var y = 0; y < Math.floor(Math.sqrt(numOfMarkers)); y++) {

      const latitude = lat + y * 0.003
      const longitude = lng + i * 0.003

      markerDataArr.push({
        location: {
          latitude,
          longitude,
        },
        name: "Sketchy Parking" + y + "-" + i,
        image: "https://whatever.com",
        contactName: "Nikolakis",
        phone: "69333333",
        latitude,
        longitude,
        address: "Efroniou 43",
        "is_active": true,
        "pricing": [
          {
            "cost": 50,
            "minutes": 30,
            "available": true
          },
          {
            "cost": 100,
            "minutes": 60,
            "available": true
          },
          {
            "cost": 150,
            "minutes": 90,
            "available": true
          },
          {
            "cost": 200,
            "minutes": 120,
            "available": true
          },
          {
            "cost": 400,
            "minutes": 150,
            "available": true
          },
          {
            "cost": 600,
            "minutes": 180,
            "available": true
          }
        ],
        // lat: lat + y * 0.003,
        // lng: lng + i * 0.003,
        id: i + "," + y
      })
    }
  }

  return { result: markerDataArr };
}


WEBAPI.baseUrlAPP = BASE_URL_APP;
WEBAPI.baseUrlSSO = BASE_URL_SSO;
WEBAPI.enableLogging = true;
WEBAPI.redirectPaymentPage = WEBAPI.constructURL("cards/redirect_page", "SSO")
// WEBAPI.enableLogging = false;

export default WEBAPI;

