import moment from "moment";

export const getDateTime = () => {
    return moment().format("DD/MM/YYYY HH:mm")
}

export const getParamsFromUrl = () => {
    var url = window.location.search.substring(1);
    var qArray = url.split('&');
    window.parameters = {}
    qArray.map((item) => {
        var pArr = item.split('=')
        var key = pArr[0];
        var value = pArr[1];
        window.parameters[[key]] = value
    })
}