
import { get } from "lodash";
import {
    isWeb,
} from "./platformCheck";
import { translate } from "./TranslationService";

//GT:WEB_DEP
import platform from "platform"
//TODORN: add actual platform information


//GT:WEB_DEP
const store = window.store;



export const getPlatformData = () => {
    if (isWeb()) {
        return platform
    } else {
        return platform;
    }
}

export const getDeviceType = () => {
    if (isWeb()) {
        return "web";
    } else {
    }
}

export const getLanguage = () => {

    //START:GT:WEB_DEP
    let lang = navigator.language.split("-")[0];
    const isAthens = get(store.getState(), "otherReducer.params.setCountry") === "el"
    if (isAthens) {
        // console.log("\n\n\nFORCING GREECE LANGUAGE\n\n\n")
        //forcing greek in athens
        lang = "el";
    }
    return lang;
    //STOP:GT:WEB_DEP

}

export const getInitialParams = () => {
    if (isWeb()) {
        let urlParams = window.location.href.split("?")[1];
        return paramStringToObject(urlParams);
    } else {
    }
}

function paramStringToObject(paramString) {
    let paramString2 = decodeURIComponent(paramString);
    var paramPairs = paramString2.split("&");

    var retObj = {};
    paramPairs.forEach(item => {
        var keyVal = item.split("=");
        retObj[keyVal[0]] = keyVal[1]
    })

    return retObj;
}


export const removeAcccents = (input) => {
    if (!input || !input.normalize) {
        return remove_accents(input);
    }
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}

export const removeAccentsAndUpper = (input) => {
    return removeAcccents(input).toUpperCase()
}

function remove_accents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents = "άέήίόύώΆΈΉΊΌΎΏ";
    var accentsOut = "αεηιουωΑΕΗΙΟΥΩ";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');

    return strAccentsOut;
}

export const looseStrCmpr = (string1, string2) => {
    if (removeAcccents(string1.toLowerCase()).indexOf(removeAcccents(string2.toLowerCase())) === -1) {
        return false;
    }
    return true;
}

export const areFieldsValid = (fieldsObj) => {

    let result = Object.keys(fieldsObj).filter(key => {
        let value = fieldsObj[key];

        if (key === "email") {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            //we re removing whitespaces as they're sanitised server side
            return !re.test(String(value).toLowerCase().replace(/\s/g, ""));
        }

        if (key === "password") {
            return !(value.length > 5);
        }

        if (key === "name" || key === "surname" || key === "nickname") {
            return !(value.length > 2)
        }

        return false;
    })

    return result.length === 0;
}

export const getErrorForFields = (fieldsObj) => {
    let result;
    Object.keys(fieldsObj).forEach(key => {
        let value = fieldsObj[key];

        if (key === "email") {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            //we re removing whitespaces as they're sanitised server side
            if (!re.test(String(value).toLowerCase().replace(/\s/g, ""))) {
                result = translate("Please insert a valid email");
            }
        }

        if (key === "password") {
            if (!(value.length > 5)) {
                result = translate("Password needs to be at least 6 digits");
            }
        }

        if (key === "name") {
            if (!(value.length > 2)) {
                //todo translate
                result = "Το όνομα πρέπει να έιναι μεγαλύτερο απο 2 χαρακτήρες";
            }
        }

        if (key === "surname") {
            if (!(value.length > 2)) {
                //todo translate
                result = "Το επίθετο πρέπει να έιναι μεγαλύτερο απο 2 χαρακτήρες";
            }
        }

        if (key === "nickname") {
            if (!(value.length > 2)) {
                //todo translate
                result = "Το ψευδόνυμο πρέπει να έιναι μεγαλύτερο απο 2 χαρακτήρες";
            }
        }
    })

    return result;
}

export const wrapWithDelay = (promise, minDelay = 0) => {
    let timeStart = new Date().getTime()

    return promise
        .then((data) => {
            return new Promise((res) => {

                let timeEnd = new Date().getTime();

                let timeDifference = timeEnd - timeStart;

                if (timeDifference >= minDelay) {
                    res(data)
                } else {
                    setTimeout(() => {
                        res(data)
                    }, minDelay - timeDifference)
                }
            })
        })
}

export const versionToNumber = (val) => {
    return parseInt(val.replace(/[.]/g, ""))
}