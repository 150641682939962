//OTHER
export const NOTHING = "NOTHING";
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_RESPONSE = "GET_COUNTRIES_RESPONSE";
export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY";
export const SET_SELECTED_LANGUAGE = "SET_SELECTER_LANGUAGE";
export const SET_INTERNET_STATUS = "SET_INTERNET_STATUS"
export const POST_ERROR = "POST_ERROR";

export const GET_AUTHORITIES_REQUEST = "GET_AUTHORITIES_REQUEST";
export const GET_AUTHORITIES_RESPONSE = "GET_AUTHORITIES_RESPONSE";
export const GET_TRANSLATIONS_REQUEST = "GET_TRANSLATIONS_REQUEST";
export const GET_TRANSLATIONS_RESPONSE = "GET_TRANSLATIONS_RESPONSE";
export const POST_REGISTER_REQ = "POST_REGISTER_REQ";
export const POST_REGISTER_RES = "POST_REGISTER_RES";
export const POST_LOGIN_REQ = "POST_LOGIN_REQ";
export const POST_LOGIN_RES = "POST_LOGIN_RES";
export const POST_ACCOUNT_REQ = "POST_ACCOUNT_REQ";
export const POST_ACCOUNT_RES = "POST_ACCOUNT_RES";
export const SET_ORIGINAL_URL_PARAMS = "SET_ORIGINAL_URL_PARAMS";
export const UNSET_ORIGINAL_URL_PARAMS = "UNSET_ORIGINAL_URL_PARAMS";
export const GET_MODULES_REQ = "GET_MODULES_REQ"
export const GET_MODULES_RES = "GET_MODULES_RES"

export const REFRESH_TOKEN_RES = "REFRESH_TOKEN_RES";

//PROFILE

export const CHANGE_PROFILE = "";
export const POST_PROFILE_REQ = "POST_PROFILE_REQ";
export const POST_PROFILE_RES = "POST_PROFILE_RES";
export const GET_PROFILE_REQ = "GET_PROFILE_REQ";
export const GET_PROFILE_RES = "GET_PROFILE_RES";

//SELECT AUTH

export const SET_SELECTED_AUTHORITY = "SET_SELECTED_AUTHORITY";

//MESSAGES
export const GET_MESSAGES_REQ = "GET_MESSAGES_REQ";
export const GET_MESSAGES_RES = "GET_MESSAGES_RES";
export const GET_MORE_MESSAGES_REQ = "GET_MORE_MESSAGES_REQ";
export const GET_MORE_MESSAGES_RES = "GET_MORE_MESSAGES_RES";
export const POST_POLL_REQ = "POST_POLL_REQ";
export const POST_POLL_RES = "POST_POLL_RES";
export const GET_SINGLE_MESSAGE_REQ = "GET_SINGLE_MESSAGE_REQ"
export const GET_SINGLE_MESSAGE_RES = "GET_SINGLE_MESSAGE_RES"

//REPORTS
export const GET_MY_REPORTS_REQ = "GET_MY_REPORTS_REQ";
export const GET_MY_REPORTS_RES = "GET_MY_REPORTS_RES";
export const GET_SINGLE_REPORT_REQ = "GET_SINGLE_REPORT_REQ";
export const GET_SINGLE_REPORT_RES = "GET_SINGLE_REPORT_RES";

//FAQS
export const GET_FAQS_REQ = "GET_FAQS_REQ";
export const GET_FAQS_RES = "GET_FAQS_RES";

//PHONES
export const GET_PHONES_REQ = "GET_PHONES_REQ";
export const GET_PHONES_RES = "GET_PHONES_RES";

//SIDEMENU
export const TOGGLE_SIDE_MENU = "TOGGLE_SIDE_MENU";

//MYCITY
export const GET_REPORTS_FOR_AUTHORITY_REQ = "GET_REPORTS_FOR_AUTHORITY_REQ";
export const GET_REPORTS_FOR_AUTHORITY_RES = "GET_REPORTS_FOR_AUTHORITY_RES";
export const GET_POIS_FOR_AUTHORITY_REQ = "GET_POIS_FOR_AUTHORITY_REQ";
export const GET_POIS_FOR_AUTHORITY_RES = "GET_POIS_FOR_AUTHORITY_RES";
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS";

//HOME
export const SET_HOME_DATA = "SET_HOME_DATA";

//NEW REPORT
export const NR_SET_NAVIGATION = "NR_SET_NAVIGATION";
export const NR_SET_SELECTED_AUTHORITY = "NR_SET_SELECTED_AUTHORITY";
export const NR_SET_SELECTED_AUTHORITY_ISSUES = "NR_SET_SELECTED_AUTHORITY_ISSUES";
export const NR_SET_SELECTED_ISSUE = "NR_SET_SELECTED_ISSUE";
export const NR_SET_DESCRIPTION = "NR_SET_DESCRIPTION";
export const NR_SET_URGENT = "NR_SET_URGENT";
export const NR_ADD_PHOTO = "NR_ADD_PHOTO";
export const NR_POST_REPORT = "NR_POST_REPORT";
export const POST_REPORT_REQ = "POST_REPORT_REQ";
export const POST_REPORT_RESP = "POST_REPORT_RESP";
export const NR_SET_NEW_CENTER_AND_ADDRESS = "NR_SET_NEW_CENTER_AND_ADDRESS";
export const NR_PURGE_REDUCER = "NR_PURGE_REDUCER";
// export const INCREMENT_STEPS_COMPLETED = "INCREMENT_STEPS_COMPLETED";

//SYSTEM
export const GET_SYSTEM_STATUS = "GET_SYSTEM_STATUS";
export const SET_FORGOT_PASS_URL = "SET_FORGOT_PASS_URL";

//FORMS
export const GET_FORMS_REQ = "GET_FORMS_REQ";
export const GET_FORMS_RES = "GET_FORMS_RES";
export const GET_SUPPORTED_FORMS_REQ = "GET_SUPPORTED_FORMS_REQ";
export const GET_SUPPORTED_FORMS_RES = "GET_SUPPORTED_FORMS_RES";
export const POST_FORM_REQ = "POST_FORM_REQ";
export const POST_FORM_RES = "POST_FORM_RES";
export const POST_FORM_PAYMENT_RES = "POST_FORM_PAYMENT_RES";
export const POST_FORM_PAYMENT_REQ = "POST_FORM_PAYMENT_REQ";
export const GET_FINE_DETAILS_REQ = "GET_FINE_DETAILS_REQ";
export const GET_FINE_DETAILS_RES = "GET_FINE_DETAILS_RES";


//CALENDAR
export const GET_CALENDAR_REQ = "GET_CALENDAR_REQ";
export const GET_CALENDAR_RES = "GET_CALENDAR_RES";

//PARKING
export const GET_TICKETS_RES = "GET_TICKETS_RES";
export const POST_ACTIVATE_TIKCET_TIME_RES = "POST_ACTIVATE_TIKCET_TIME_RES";
export const GET_GEOZONES_REQ = "GET_GEOZONES_REQ";
export const GET_GEOZONES_RES = "GET_GEOZONES_RES";
//WALLET
export const GET_WALLET_RES = "GET_WALLET_RES";
export const GET_WALLET_TRANSACTIONS_RES = "GET_WALLET_TRANSACTIONS_RES";
export const GET_MORE_WALLET_TRANSACTIONS_REQ = "GET_MORE_WALLET_TRANSACTIONS_REQ"
export const GET_MORE_WALLET_TRANSACTIONS_RES = "GET_MORE_WALLET_TRANSACTIONS_RES";
export const GET_WALLET_TOPUP_OPTIONS_REQ = "GET_WALLET_TOPUP_OPTIONS_REQ";
export const GET_WALLET_TOPUP_OPTIONS_RES = "GET_WALLET_TOPUP_OPTIONS_RES";
export const TOPUP_WALLET_REQ = "TOPUP_WALLET_REQ";
export const TOPUP_WALLET_RES = "TOPUP_WALLET_RES";

//CARDS
export const GET_CARDS_REQ = "GET_CARDS_REQ";
export const GET_CARDS_RES = "GET_CARDS_RES";
export const SELECT_CARD_FROM_WALLET = "SELECT_CARD_FROM_WALLET";

//VERIFICATION
export const POST_VERIFY_REQ_REQ = "POST_VERIFY_REQ_REQ";
export const POST_VERIFY_REQ_RES = "POST_VERIFY_REQ_RES";
export const POST_ACTUAL_VERIFY_REQ = "POST_ACTUAL_VERIFY_REQ";
export const POST_ACTUAL_VERIFY_RES = "POST_ACTUAL_VERIFY_RES";

export const SUBSCRIBE_TO_POI_RES = "SUBSCRIBE_TO_POI_RES"
export const UNSUBSCRIBE_FROM_POI_RES = "UNSUBSCRIBE_FROM_POI_RES"

export const SET_AUTH_TOKEN_FROM_OLD_APP = "SET_AUTH_TOKEN_FROM_OLD_APP";
export const SET_UUID_FOR_PUSHWOOSH = "SET_UUID_FOR_PUSHWOOSH";
export const SET_BADGE_NUMBER = "SET_BADGE_NUMBER";

//DISCUSSIONS

export const GET_DISCUSSIONS_REQ = "GET_DISCUSSIONS_REQ"
export const GET_DISCUSSIONS_RES = "GET_DISCUSSIONS_RES"